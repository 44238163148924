.slider {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  text-align: center;
}

.slide-image {
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease;
}

.fade {
  opacity: 0;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.3s;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  user-select: none;
}

.prev {
  left: 0;
  border-radius: 0 3px 3px 0;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
