.register_car_body {
  padding: 20px;
}

.register_car_body h2 {
  text-align: center;
  margin: 20px 0;
  color: #fff;
  font-size: 26px;
}

.register_car_body_form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
}

.form_group {
  display: flex;
  flex-direction: column;
}

.form_group label {
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
}

.form_group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.register_car_body_form_btn_send {
  text-align: center;
  margin-top: 20px;
}

.register_car_body_form_btn_send button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register_car_body_form_btn_send button:hover {
  background-color: #0056b3;
}
