.about_us_page {
  margin: 2rem 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.about_us_page_body {
  width: 80%;
}

.about_us_page h1 {
  color: aliceblue;
  margin: 20px 0 10px 0;
  font-weight: 600;
  font-size: 20px;
}

.about_us_page p {
  color: #fff;
  font-size: 16px;
  padding: 8px 0;
}

.about_us_page img {
  padding: 1rem 0;
}

.footer_last_about_us_page {
  margin-top: 2rem;
}
