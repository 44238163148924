.menu_header_drop_page {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  /* border-bottom: 3px solid #ffec00; */
}

.dropbtn_menu_header {
  color: #fff;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.dropbtn_menu_header:hover {
  /* background-color: #f9f9f9; */
  /* border-top: 2px solid #003c77; */
  color: #ffec00;
}

.dropdown_menu_header {
  position: relative;
  display: inline-block;
}

.dropdown_content_menu_header {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown_content_menu_header a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown_content_menu_header a:hover {
  background-color: #ffec00;
}

.dropdown_menu_header:hover .dropdown_content_menu_header {
  display: block;
}

.dropdown_menu_header:hover .dropbtn {
  background-color: #ffec00;
}
