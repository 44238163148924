@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'sahel';
  src: url('./assets/font/Sahel.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair';
  src: url('./assets/font/Helvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Helvetica';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rtl {
  direction: rtl;
  font-family: 'sahel';
}
